<template>
  <Sending />
  <div class="container">
    <div class="msg">
      <div class="msg__title">ご注文ありがとうございました</div>
      <div class="msg__text">
        お客様にとって最高のTシャツになるよう最後までサポートさせていただきます。<br class="pcOnly" />
        下記から決済いただき、確認後サンプルTシャツの製作に入らせていただきます。
      </div>
      <div class="msg__btn">
        <form method="post" action="https://cart.ec-sites.jp/sp_step2/pc/pk00/">
          <input type="hidden" name="es_item_qty" value="1" />
          <input type="hidden" name="es_charset" value="sjis" />

          <input type="hidden" name="es_item_id" value="12" />
          <input type="hidden" name="es_shop_id" value="4777" />
          <input type="hidden" name="es_stock_attr_flag" value="0" />
          <button type="submit" name="es_submit" value="カートに入って決済する">Tシャツをご購入のお客様</button>
        </form>
        <form method="post" action="https://cart.ec-sites.jp/sp_step2/pc/pk00/">
          <input type="hidden" name="es_item_qty" value="1" />
          <input type="hidden" name="es_charset" value="sjis" />

          <input type="hidden" name="es_item_id" value="13" />
          <input type="hidden" name="es_shop_id" value="4777" />
          <input type="hidden" name="es_stock_attr_flag" value="0" />
          <button type="submit" name="es_submit" value="カートに入って決済する">ロングTシャツをご購入のお客様</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Sending from '../components/Sending.vue'
export default {
  components: { Sending },
}
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 120px - 300px);
  margin-bottom: -5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 80px - 150px);
  }
}
.msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    font-size: 2.5rem;
    margin-bottom: 5rem;
  }

  &__text {
    font-size: 14px;
    text-align: center;
    line-height: 1.8;

    @media screen and (max-width: 425px) {
      text-align: left;
    }
  }

  &__btn {
    margin-top: 4rem;
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    & button {
      width: 300px;
      margin: 10px 10px;
      color: var(--color-light);
      background-color: transparent;
      padding: 15px 20px;
      border: 1px solid #555;
      border-radius: 100px;
      font-size: 1.5rem;
      font-weight: 300;
      cursor: pointer;
      transition: border 0.3s ease-out;

      &:hover {
        border: 1px solid #aaa;
      }
    }
  }
}
</style>
